import { Component, OnInit } from '@angular/core';
import { LineLiffService } from 'src/app/services/line-liff.service';
import { LineProfile } from 'src/app/services/line-profile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  base_url(arg0: string) {
    throw new Error('Method not implemented.');
  }

  constructor(private liffServ: LineLiffService) { }

  lineProfile: LineProfile = {
    displayName: '',
    pictureUrl: '',
    statusMessage: '',
    // userId: '',
    userId: 'U5ce35ed48943552ace37ac9926240b65',
  };
  async ngOnInit(): Promise<void> {
    this.lineProfile = await this.liffServ.init(environment.lffApps.watermarkAppId);
    console.log(this.lineProfile)
  }

  setDefaultImage(img: any) {
    img.onerror = null;
    img.src = 'assets/img/default-user-icon.png';
  }
}
