export const environment = {
    production: false,
    apiHost: 'https://toyku-api-dev.i-thee.com',
    // apiHost: 'https://localhost:4200/',
    lffApps: {
        watermarkAppId: '2000068180-wYLW39K4', //older
        productListAppId: '2000190271-ljrXP8XA',
        userListAppId: '2000190271-BP51PG17',
        adminListAppId: '2000190271-qknJ6WJD',
        orderListAppId: '2000190271-6DGNMBN3',
        myProfileAppId: '2000190271-64mex8e2'
    },
}