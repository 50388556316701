<link rel="stylesheet" href="/assets/css/style.css">
<link rel="stylesheet" href="/assets/css/bootstrap.min.css">
<style>
    body {
        /* background-image: url("/assets/img/bg-admin.jpg"); */
        background: #007bff;
        background: linear-gradient(to right, #0062E6, #33AEFF);
        background-size: auto;
        /* <------ */
        background-repeat: repeat;
        background-position: center center;
        height: 100vh;
        width: 100vw;
    }
</style>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">

<script src="https://code.jquery.com/jquery-3.6.4.js" integrity="sha256-a9jBBRygX1Bh5lt8GZjXDzyOB+bWve9EiO7tROUtj/E=" crossorigin="anonymous"></script>
<script src="/assets/js/bootstrap.bundle.min.js"></script>

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top" style="padding-bottom: 20px;">
    <div class="container">
        <a class="navbar-brand" href="/admin/home">
            <img src="assets/img/logo.png" alt="My Website Logo" height="50">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a class="nav-link" href="{{base_url}}/home">หน้าแรก</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        เพิ่มเติม
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                            <a class="dropdown-item" href="{{base_url}}/watermark">ลายน้ำ</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{base_url}}/products">สินค้า</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{base_url}}/customers">ลูกค้า</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{base_url}}/orders">การซื้อขาย</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="{{base_url}}/spec">ข้อกำหนด</a>
                </li>
                <li class="nav-item">
                    <div class="dropdown ms-auto" style="padding:0 5px;">
                        <a class="dropdown-toggle" style="text-decoration:none" href="#" role="button" id="userDropdown"
                            data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="{{lineProfile.pictureUrl}}" alt="User Icon" height="40"
                                class="rounded-circle"><!--- onerror="setDefaultImage(this);"> -->
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                            <li><a class="dropdown-item" href="{{base_url}}/profile">Manage
                                    Profile</a></li>
                            <li>
                                <hr class="dropdown-divider">
                            </li>
                            <li><a class="dropdown-item" href="{{base_url}}/logout">Logout</a></li>
                        </ul>
                    </div>

                </li>
            </ul>
        </div>
    </div>
</nav>